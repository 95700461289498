<template>
  <div>
    <v-col cols="12" sm="5" v-if="$route.params.id">
      <v-alert
        v-if="product.uploaded_images.length == 0"
        type="warning"
        dense
        class="text-center black--text font-weight-bold"
      >
        <small> אין תמונות כעת</small>
      </v-alert>
      <v-text-field
        @click="dialog = true"
        readonly
        v-if="$route.params.id && product.uploaded_images.length != 0"
        :value="`מספר התמונות עד עכשיו( ${product.uploaded_images.length})`"
      >
        <template slot="append-outer">
          <v-btn
            @click="dialog = true"
            elevation="1"
            color="blue darken"
            class="white--text"
            small
            fab
          >
            <v-icon>visibility</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-col>

    <v-dialog max-width="600" v-model="dialog">
      <v-card>
        <v-card-title>
          <v-btn @click="dialog = false" large icon>
            <v-icon large>close</v-icon>
          </v-btn>
        </v-card-title>
        <!--/ card title -->
        <v-card-text>
          <v-carousel
            height="300"
            :show-arrows="p_imgs"
            hide-delimiters
            v-model="carousel"
          >
            <v-carousel-item
              class="text-center"
              v-for="(image, i) in product.uploaded_images"
              :key="i"
            >
              <img
                style="width: 80%"
                :src="`${$store.state.def_path}/${image.img}`"
              />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <!--/ card text -->
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-btn @click="delete_image()" color="error">حذف الصورة</v-btn>
        </v-card-actions>
      </v-card>
      <!--/ card -->
    </v-dialog>

    <v-row v-for="(img, i) in product.images" :key="i">
      <v-col cols="6">
        <v-file-input
          background-color="white"
          outlined
          dense
          prepend-icon=""
          v-model="product.images[i]"
          hide-details
          label="תמונה"
        >
          <template slot="append">
            <v-btn icon color="primary">
              <v-icon>image</v-icon>
            </v-btn>
          </template>
        </v-file-input>
      </v-col>
      <v-col>
        <v-btn
          @click="product.images.splice(i, 1)"
          v-if="i != 0"
          height="45"
          depressed
          color="error"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-btn
      @click="product.images.push(null)"
      outlined
      color="primary"
      class="mt-5"
    >
      תמונה חדשה
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Imgs",
  props: ["product"],
  data() {
    return {
      dialog: false,
      carousel: 0,
      delete_loading: false,
    };
  },
  computed: {
    p_imgs() {
      return this.product.uploaded_images.length > 1;
    },
  },
  methods: {
    delete_image() {
      let img_id = this.product.uploaded_images[this.carousel].id;

      this.delete_loading = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `/products/image/${img_id}`,
            method: "delete",
          },
        })
        .then((res) => {
          
          this.product.uploaded_images.splice(this.carousel, 1);
          this.delete_loading = false;
          if (this.product.uploaded_images.length == 0) {
            this.dialog = false;
          }
        });
    },
  },
};
</script>
