<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <div v-if="!edit_loaded">
          <v-skeleton-loader v-for="a in 5" :key="a" type="article"></v-skeleton-loader>
        </div>

        <v-card v-if="edit_loaded">
          <v-card-title>מוצר חדש</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form">
              <Form @re_exist="barcode_exist = false" :barcode_exist="barcode_exist" :product="product"></Form>
              <Imgs :product="product"></Imgs>
            </v-form>
          </v-card-text>
        </v-card>
        <div v-if="edit_loaded" class="d-flex mt-4  mb-10 justify-end">
          <v-btn @click="save()" :disabled="save_loader" :loading="save_loader" x-large depressed height="40"
            color="black white--text ms-3">שמירה</v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
  import Form from "@/components/products/form";
  import Imgs from "@/components/products/imgs";

  export default {
    name: "product_page",
    data() {
      return {
        edit_loaded: true,
        barcode_exist: null,
        save_loader: false,
        product: {
          name: "",
          barcode: "",
          quantity: 0,
          quantity_alert: 0,
          maximum: null,
          price: 0,
          sku: null,
          images: [null],
          status: 1,
          sections_ids: [],
          uploaded_images: [],
          brand_id: null,
          sort: null,
          tex: 1,
        },
        items_box: [],
        search: null,
      };
    },
    components: {
      Form,
      Imgs,
    },
    methods: {
      filter(item, queryText, itemText) {
        if (item.header) return false;
        const hasValue = (val) => (val != null ? val : "");

        const text = hasValue(itemText);
        const query = hasValue(queryText);

        return (
          text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
          -1
        );
      },
      save() {
        if (this.$refs.form.validate() && !this.save_loader) {
          this.save_loader = true;
          let formData = new FormData();
          let arrays = [
            "sections_ids",
            "resources",
            "paragraphs",
            "tags",
            "images",
            "generations"
          ];
          Object.entries(this.product).forEach((e, i) => {
            if (!arrays.includes(e[0])) {
              formData.append(e[0], e[1]);
            } else if (
              e[0] == "sections_ids" ||
              e[0] == "resources" ||
              e[0] == "tags" ||
              e[0] == "generations"
              // e[0] == "status"
            ) {
              e[1].forEach((x, y) => {
                formData.append(`${e[0]}[${y}]`, x != null ? x : '');
              });
            } else if (e[0] == "images") {
              e[1].forEach((x, y) => {
                if (x != null) {
                  formData.append(`${e[0]}[${y}]`, x);
                }
              });
            } else if (e[0] == "paragraphs") {
              e[1].forEach((x, y) => {
                formData.append(`${e[0]}[${y}][title]`, x.title);
                formData.append(`${e[0]}[${y}][description]`, x.description);
              });
            } else if (e[0] == "resources") {
              e[1].forEach((x, y) => {
                formData.append(`${e[0]}[${y}]`, x);
              });
            } else if (e[0] == "generations") {
              e[1].forEach((x, y) => {
                formData.append(`${e[0]}[${y}]`, x);
              });
            }
          });

          this.$store
            .dispatch("public__request", {
              config: {
                url: this.$route.params.id
                  ? `products/${this.$route.params.id}`
                  : "products",
                method: "post",
              },
              data: formData,
            })
            .then((res) => {
              this.$router.push("/products");
            })
            .catch((err) => {
              this.save_loader = false;
              let errors = err.response.data.data;
              console.log(err.response);
              Object.keys(errors).forEach((e) => {
                errors[e].forEach((s) => {
                  if (s.includes("already been taken")) {
                    this.$set(this, `${e}_exist`, true);
                  }
                });
              });
            });
        }
      },
      edit() {
        this.$store
          .dispatch("public__request", {
            config: {
              url: `products/${this.$route.params.id}`,
              method: "get",
            },
          })
          .then((res) => {

            this.product = Object.assign({}, res.data.data);
            this.edit_loaded = true;
          });
      },
    },
    beforeMount() {
      if (this.$route.params.id) {
        this.edit_loaded = false;
      }
    },
    mounted() {
      if (this.$route.params.id) {
        this.edit();
      }
    },
  };
</script>