<template>
  <v-row class="basic mt-2">
    <v-col class="py-0" cols="6" md="3">
      <v-text-field
        background-color="white"
        outlined
        dense
        v-model="product.name"
        :rules="[(v) => !!v || 'שדה חובה']"
      >
        <template slot="label">
          <strong class="red--text"> *</strong>
          שם מוצר
        </template>
      </v-text-field>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <v-text-field
        background-color="white"
        outlined
        dense
        @keydown="$emit('re_exist')"
        v-model="product.barcode"
        :rules="[(v) => !!v || 'שדה חובה', !barcode_exist || 'שדה חובה']"
      >
        <template slot="label">
          <strong class="red--text"> *</strong>
          ברקוד
        </template>
      </v-text-field>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <v-text-field
        background-color="white"
        outlined
        dense
        label="כמות"
        type="number"
        v-model="product.quantity"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <v-text-field
        background-color="white"
        outlined
        dense
        label="כמות להתראה"
        type="number"
        v-model="product.quantity_alert"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <v-text-field
        background-color="white"
        outlined
        dense
        label="כמות מקסימלית"
        type="number"
        v-model="product.maximum"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <v-text-field
        background-color="white"
        outlined
        dense
        label="מספר מדף"
        v-model="product.sku"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <v-text-field
        background-color="white"
        outlined
        dense
        label="עלות"
        v-model="product.price"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <div class="white mb-3">
        <v-menu
          :close-on-content-click="false"
          offset-y
          v-model="section_menu"
          min-width="300"
          max-width="300"
          scrollable
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-bind="attrs"
              dense
              hide-details
              v-on="on"
              readonly
              :rules="[(v) => !product.sections_ids.length == 0 || 'שדה חובה']"
              v-model="sections_input"
            >
              <template slot="label">
                <strong class="red--text"> *</strong>
                קטגוריות
              </template>
            </v-text-field>
          </template>
          <v-expansion-panels
            accordion
            style="max-height: 500px; overflow-y: auto"
          >
            <v-expansion-panel
              v-for="(item, index) in sections_ini"
              :key="index"
            >
              <v-expansion-panel-header
                @click="open_section(item, index)"
                disable-icon-rotate
                :class="{ primary: item.num_padding != 0 }"
              >
                <template v-slot:actions>
                  <v-icon :class="{ 'white--text': item.num_padding != 0 }">{{
                    item.checked ? "add" : "remove"
                  }}</v-icon>
                </template>
                <span :class="{ 'white--text': item.num_padding != 0 }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon
                          :class="{ 'white--text': item.num_padding != 0 }"
                          >error_outline</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>

                  <v-chip x-small class="me-2">
                    {{ item.num_padding }}
                  </v-chip>
                  {{ item.name.slice(0, 18) }}
                  {{ item.name.length >= 18 ? "..." : "" }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list color="grey lighten-4">
                  <v-list-item @click="select_all(index, item)">
                    <v-list-item-content> الكل </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox v-model="item.all"></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    class="child_list mb-3"
                    @click="
                      (child.checked = !child.checked),
                        select_section(item, child)
                    "
                  >
                    <v-list-item-content>
                      {{ child.name.slice(0, 18) }}
                      {{ child.name.length >= 18 ? "..." : "" }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox v-model="child.checked"></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-menu>
      </div>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <v-text-field
        background-color="white"
        outlined
        dense
        label="sort"
        v-model="product.sort"
      ></v-text-field>
    </v-col>

    <v-col class="py-0" cols="6" md="3">
      <v-select
        background-color="white"
        outlined
        dense
        item-text="name"
        item-value="id"
        :items="brands"
        :loading="brands.length == 0"
        v-model="product.brand_id"
      >
        <template slot="label"> מותג </template>
      </v-select>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <v-select
        label="סוג מוצר בחשבונית"
        outlined
        dense
        background-color="white"
        :items="taxItems"
        item-text="text"
        item-value="value"
        v-model="product.tax"
      >
      </v-select>
    </v-col>
    <v-col class="py-0" cols="6" md="3">
      <v-select
        label="סטטוס"
        outlined
        dense
        background-color="white"
        :items="statuses"
        v-model="product.status"
      >
      </v-select>
    </v-col>

    
  </v-row>
</template>

<script>
export default {
  props: ["product", "barcode_exist"],
  name: "Form",
  data() {
    return {
      section_menu: false,
      items_box: [],
      search: null,
      sections: [],
      brands: [],
      statuses: [
        {
          value: 1,
          text: "פעיל",
        },
        {
          value: 0,
          text: "לא פעיל",
        },
        {
          value: 2,
          text: "חסוי",
        },
      ],
    };
  },
  computed: {
    taxItems() {
      return [
        {
          value:0,
          text:"ללא מע”מ"
        },
        {
          value:1,
          text:"כולל מע”מ"
        },
        
      ];
    },
    child_sections() {
      let sections = [];
      this.sections.forEach((e) => {
        if (e.parent_id != null) {
          sections.push(e);
        }
      });
      return sections;
    },
    sections_input() {
      let names = [];
      this.sections.forEach((ev, i) => {
        ev.children.forEach((ch, s) => {
          if (this.product.sections_ids.includes(ch.id)) {
            names.push(ch.name);
          }
        });
      });
      return names.length > 1 ? ` (${names.length})  اقسام` : names[0];
    },
    children_sections() {
      let sections = [];
      this.sections.forEach((ev, i) => {
        ev.children.forEach((ch, s) => {
          sections.push(ch);
          if (this.product.sections_ids.includes(ch.id)) {
            this.search_results.push(ch);
          }
        });
      });
      return sections;
    },
    sections_ini() {
      let sections = [];
      this.sections.forEach((ev, i) => {
        let padding = 0;
        ev.children.forEach((ch, s) => {
          if (this.product.sections_ids.includes(ch.id)) {
            ch.checked = true;
            padding++;
          } else {
            ch.checked = false;
          }
        });
        ev.num_padding = padding;
        if (padding == ev.children.length) {
          ev.all = true;
        }
        sections.push(ev);
      });
      return sections;
    },
  },
  methods: {
    filter(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    get_brands() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "brands",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.brands = Object.assign([], res.data.data);
          
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    open_section(item, index) {
      this.sections.forEach((ev, ind) => {
        if (index != ind) {
          this.sections[ind].checked = false;
        }
      });
      item.checked = !item.checked;
    },
    select_all(index, item) {
      item.all = !item.all;
      if (item.all) {
        item.children.forEach((e) => {
          this.product.sections_ids.push(e.id);
        });
      } else {
        const map = item.children.map(function (val) {
          return val.id;
        });

        const removeItems = (array, itemsToRemove) => {
          return array.filter((v) => {
            return !itemsToRemove.includes(v);
          });
        };
        this.product.sections_ids = removeItems(this.product.sections_ids, map);
      }
    },

    search_sections(ev) {
      let map = this.search_results.map(function (val) {
        return val.id;
      });
      this.product.sections_ids = map;
    },
    select_section(parent, item) {
      if (item.checked) {
        this.product.sections_ids.push(item.id);
      } else {
        let id_index = this.product.sections_ids.findIndex((e) => e == item.id);
        parent.all = false;
        this.product.sections_ids.splice(id_index, 1);
      }
    },

    compochange(prop, val) {
      this.filter_products[prop] = val;
    },
    get_sections() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "sections_filter",
            method: "get",
          },
        })
        .then((res) => {
          this.sections = Object.assign([], res.data.data);
          
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  mounted() {
    this.get_sections();
    this.get_brands();
  },
};
</script>
